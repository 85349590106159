import React, { useState } from 'react'
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../Assets/logo.png'
import { Link } from 'react-router-dom';
import { AiFillStar, AiOutlineFundProjectionScreen, AiOutlineHome, AiOutlineUser } from 'react-icons/ai'
import { CgFileDocument, CgGitFork } from 'react-icons/cg'
import Button from 'react-bootstrap/Button';
import { SiProgress } from 'react-icons/si'

function NavBar() {

    const [expand, updateExpanded] = useState(false);
    const [navColor, updateNavbar] = useState(false);

    function scrollHandler() {
        if (window.scrollY >= 20) {
            updateNavbar(true);
        } else {
            updateNavbar(false);
        }
    }

    window.addEventListener('scroll', scrollHandler);

    return (
        <Navbar expanded={expand} fixed='top' expand='md' className={navColor ? 'sticky' : 'navbar'}>
            <Container>
                <Navbar.Brand href='/' className='d-flex'>
                    <img src={logo} className="img-fluid logo" alt='brand' />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls='responsive-navbar-nav' onClick={() => {
                    updateExpanded(expand ? false : "expanded")
                }}>
                    <span></span>
                    <span></span>
                    <span></span>
                </Navbar.Toggle>

                <Navbar.Collapse id='responsive-navbar-nav'>
                    <Nav className='ms-auto' defaultActiveKey="#home">
                        <Nav.Item>
                            <Nav.Link as={Link} to="/" onClick={() => updateExpanded(false)}>
                                <AiOutlineHome style={{ marginBottom: "2px" }} />{" "} Home
                            </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                            <Nav.Link as={Link} to="/about" onClick={() => updateExpanded(false)}>
                                <AiOutlineUser style={{ marginBottom: "2px" }} />{" "}About
                            </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                            <Nav.Link as={Link} to="/project" onClick={() => updateExpanded(false)}>
                                <AiOutlineFundProjectionScreen style={{ marginBottom: "2px" }} />{" "}Projects
                            </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                            <Nav.Link as={Link} to="/resume" onClick={() => updateExpanded(false)}>
                                <CgFileDocument style={{ marginBottom: "2px" }} /> Resume
                            </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                            <Nav.Link as={Link} to="/skills" onClick={() => updateExpanded(false)}>
                                <SiProgress style={{ marginBottom: "2px" }} /> Skills

                            </Nav.Link>
                        </Nav.Item>

                        <Nav.Item className='fork-btn'>
                            <Button href='/' target='_blank' className='fork-btn-inner'>
                                <CgGitFork style={{ fontSize: "1.2rem" }} /> {" "}
                                <AiFillStar style={{ fontSize: "1.1rem" }} />
                            </Button>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default NavBar